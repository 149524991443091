import styled from "styled-components";
import {ReactComponent as E195LogoMedium} from "./assets/E195-logo-medium.svg";
import {ReactComponent as SpinningCompass} from "./assets/icons/compass.svg";
import {useEffect, useLayoutEffect, useState} from "react";
import {NotReadyForPrerender} from "./common/prerender";
import {debounce} from "lodash";

//don't use material ui in here, keep imports to a minimum

//TODO CHANGE TO LARGE LOGO FOR ALL

const tinyScreenWidth = 600;
const standardLogoWidth = 300;
const standardCompassWidth = 70;

const displayPageAfterMillis = 1500;
const takingAWhileTimeoutMillis = 10000;

const loadingJustFineMessage1 = "Loading the app...";
const loadingJustFineMessage2 = "hang in there!";
const takingAWhileMessage1 = "Hmm this is taking a while...";
const takingAWhileMessage2 = "How's your internet connection?";
const failureMessage1 = "We're down for maintenance...";
const failureMessage2 = "but we'll be back up soon!";

const capitalizeFirstLetter = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export type StartUpProps = {
  isFailure: boolean;
};

const StartUpGrid = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f7f4f2;
`;

const StandardStartUpGrid = styled(StartUpGrid)`
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
`;

const StandardLogoItem = styled.div`
  grid-column: 1 / 4;
  grid-row: 1 / 2;
`;

const StandardStyledLogo = styled(E195LogoMedium)`
  margin-left: 5px;
`;

const StandardLoadingItem = styled.div`
  grid-column: 1 / 4;
  grid-row: 2 / 3;
`;

const TinyStartUpGrid = styled(StartUpGrid)`
  grid-template-rows: repeat(3, 1fr);
  justify-items: center;
`;

const TinyLogoItem = styled.div`
  grid-row: 1 / 2;
`;

const TinyLoadingItem = styled.div`
  grid-row: 2 / 3;
`;

const LoadingFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: -15px;
`;

const Typography = styled.p`
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #2e318b;
  text-align: center;
`;

const LoadingTypography = styled(Typography)<{$fontsize: string, $margintop?: string, $hidden?: boolean}>`
  font-size: ${props => props.$fontsize};
  margin: ${props => props.$margintop ? `${props.$margintop} 0 0 0` : "12px 0 0 0" };
  visibility: ${props => props.$hidden ? "hidden" : "visible"};
`;

const FailureTypography = styled(Typography)<{$fontsize: string, $margintop?: string}>`
  font-size: ${props => props.$fontsize};
  margin: ${props => props.$margintop ? `${props.$margintop} 0 0 0` : "0" };
`;

const WhileLoadingMessage = (props: {standardScreen: boolean, takingAWhile: boolean}) => {

  const fontSize = props.standardScreen ? "1.1rem" : "1rem";

  return (
    <LoadingFlexBox>
      <SpinningCompass
        width={`${standardCompassWidth}px`}
        height={`${standardCompassWidth}px`}
        fill={"#2e318b"}
      />
      {
        props.takingAWhile ?
          <>
            <LoadingTypography $fontsize={fontSize}>
              {takingAWhileMessage1}
            </LoadingTypography>
            <LoadingTypography $fontsize={fontSize} $margintop={"6px"}>
              {takingAWhileMessage2}
            </LoadingTypography>
          </> :
          <>
            <LoadingTypography $fontsize={fontSize}>
              {
                props.standardScreen ?
                  `${loadingJustFineMessage1} ${loadingJustFineMessage2}` :
                  loadingJustFineMessage1
              }
            </LoadingTypography>
            <LoadingTypography
              $fontsize={fontSize}
              $margintop={"6px"}
              $hidden={true} //just gonna hide second part of just fine message for tiny screens
            >
              {capitalizeFirstLetter(loadingJustFineMessage2)}
            </LoadingTypography>
          </>
      }
    </LoadingFlexBox>
  );

};

const OnFailureMessage = (props: {standardScreen: boolean}) => {

  const fontSize = props.standardScreen ? "1.1rem" : "1rem";

  if (props.standardScreen) {
    return (
      <LoadingFlexBox>
        <FailureTypography $fontsize={fontSize}>
          {`${failureMessage1} ${failureMessage2}`}
        </FailureTypography>
      </LoadingFlexBox>
    );
  } else {
    return (
      <LoadingFlexBox>
        <FailureTypography $fontsize={fontSize}>
          {failureMessage1}
        </FailureTypography>
        <FailureTypography $fontsize={fontSize} $margintop={"6px"}>
          {capitalizeFirstLetter(failureMessage2)}
        </FailureTypography>
      </LoadingFlexBox>
    );
  }

};

const LoadingMessage = (props: {standardScreen: boolean, isFailure: boolean, takingAWhile: boolean}) => {

  return props.isFailure ?
    <OnFailureMessage standardScreen={props.standardScreen} />  :
    <WhileLoadingMessage standardScreen={props.standardScreen} takingAWhile={props.takingAWhile}/>

};

//https://css-tricks.com/working-with-javascript-media-queries/
const isScreenWiderThan = (width: number): boolean => window.matchMedia(`(min-width: ${width}px)`).matches;

const StartUpPage: React.FC<StartUpProps> = (props: StartUpProps) => {

  const [displayPageText, setDisplayPageText] = useState<boolean>(props.isFailure);
  const [standardScreen, setStandardScreen] = useState<boolean>(isScreenWiderThan(tinyScreenWidth));
  const [screenWiderThanLogo, setScreenWiderThanLogo] = useState(isScreenWiderThan(standardLogoWidth));
  const [takingAWhile, setTakingAWhile] = useState<boolean>(false);
  const [displayPageTimeout, setDisplayPageTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  const [takingAWhileTimeout, setTakingAWhileTimeout] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (displayPageTimeout === undefined) {
      const timeout = setTimeout(() => setDisplayPageText(true), displayPageAfterMillis);
      setDisplayPageTimeout(timeout);
    }
    return () => {
      if (displayPageTimeout !== undefined) {
        clearTimeout(displayPageTimeout);
        setDisplayPageTimeout(undefined);
      }
    }
  }, [displayPageTimeout]);

  useEffect(() => {
    if (takingAWhileTimeout === undefined) {
      const timeout = setTimeout(() => setTakingAWhile(true), takingAWhileTimeoutMillis);
      setTakingAWhileTimeout(timeout);
    }
    return () => {
      if (takingAWhileTimeout !== undefined) {
        clearTimeout(takingAWhileTimeout);
        setTakingAWhileTimeout(undefined);
      }
    }
  }, [takingAWhileTimeout]);

  useLayoutEffect(() => {
    let isCanceled = false;
    const updateScreenSize = debounce(() => {
      if (!isCanceled) {
        setStandardScreen(isScreenWiderThan(tinyScreenWidth));
        setScreenWiderThanLogo(isScreenWiderThan(standardLogoWidth));
      }
    }, 150, {leading: true, trailing: true});
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => {
      isCanceled = true;
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  if (standardScreen) {
    return (
      <>
        <NotReadyForPrerender caller={"startuppage"}/>
        <StandardStartUpGrid>
          <StandardLogoItem>
            <StandardStyledLogo width={"300px"}/>
          </StandardLogoItem>
          {
            displayPageText &&
              <StandardLoadingItem>
                <LoadingMessage standardScreen={true} isFailure={props.isFailure} takingAWhile={takingAWhile} />
              </StandardLoadingItem>
          }
        </StandardStartUpGrid>
      </>
    );
  } else {
    const logoWidth = screenWiderThanLogo ? standardLogoWidth : "100%";
    return (
      <>
        <NotReadyForPrerender caller={"startuppage"}/>
        <TinyStartUpGrid>
          <TinyLogoItem>
            <E195LogoMedium width={logoWidth}/>
          </TinyLogoItem>
          {
            displayPageText &&
              <TinyLoadingItem>
                <LoadingMessage standardScreen={false} isFailure={props.isFailure} takingAWhile={takingAWhile} />
              </TinyLoadingItem>
          }
        </TinyStartUpGrid>
      </>
    );
  }

};

export default StartUpPage;