import {BotInfo, Browser, BrowserInfo, detect, NodeInfo, ReactNativeInfo, SearchBotDeviceInfo} from "detect-browser";

//keep imports to a minimum
//also don't use fancy javascript... i guess

const detectedBrowsers: string[] = ["chrome", "edge", "firefox", "ie", "opera", "safari"];
type DetectedBrowser = Extract<Browser, "chrome" | "edge" | "firefox" | "ie" | "opera" | "safari">;

type SupportedBrowsers = Record<DetectedBrowser, number | "NOPE">;

const supportedBrowsers: SupportedBrowsers = {
  "chrome": 85,
  "edge": 86,
  "firefox": 80,
  "ie": "NOPE",
  "opera": 71,
  "safari": 14,
};

const displayNames: Record<DetectedBrowser, string> = {
  "chrome": "Chrome",
  "edge": "Edge",
  "firefox": "Firefox",
  "ie": "Internet Explorer",
  "opera": "Opera",
  "safari": "Safari",
};

export type BrowserSupport = "SUPPORTED" | "UNSUPPORTED" | "UNKNOWN";

type BrowserType = BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | ReactNativeInfo | null;

const isBrowserInfo = (bt: BrowserType): bt is BrowserInfo => {
  return bt !== null && bt.type === "browser";
};

const isDetectedBrowser = (b: Browser): b is DetectedBrowser => {
  return Boolean(b) && detectedBrowsers.includes(b);
};

type BrowserResult = {
  browser: DetectedBrowser;
  version: string;
  mainVersion: number;
  displayName: string;
};


const extractMainVersion = (version: string): number | undefined => {
  if (version) {
    const i = version.indexOf(".");
    const main = version.substring(0, i);
    if (main) {
      const mainInt = parseInt(main, 10);
      if (isNaN(mainInt)) {
        return undefined;
      } else {
        return mainInt;
      }
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

const detectBrowser = (): BrowserResult | undefined => {
  const browserType: BrowserType = detect();
  let result: BrowserResult | undefined = undefined;
  if (browserType && isBrowserInfo(browserType) && isDetectedBrowser(browserType.name) && browserType.version) {
    const mainVersion = extractMainVersion(browserType.version);
    if (mainVersion !== undefined) {
      return {
        browser: browserType.name,
        version: browserType.version,
        mainVersion: mainVersion,
        displayName: displayNames[browserType.name]
      };
    }
  }
  return result;
};

export const browserResult = detectBrowser();

export const isBrowserSupported = (): BrowserSupport => {
  if (browserResult) {
    const supportedVersion = supportedBrowsers[browserResult.browser];
    if (typeof supportedVersion === "number" && browserResult.mainVersion >= supportedVersion) {
      return "SUPPORTED";
    } else {
      return "UNSUPPORTED";
    }
  } else {
    return "UNKNOWN";
  }
};