import React, {Suspense} from 'react';
import ErrorBoundary from "./common/ErrorBoundary";
import StartUpPage from "./StartUpPage";
import {isBrowserSupported} from "./browsers";
import UnsupportedBrowser from "./UnsupportedBrowser";
import {isPrerenderer} from "./detectPrerenderer";

const AppMain = React.lazy(() => import("./AppMain"));

const isDevelopment = process.env.REACT_APP_ENVIRONMENT === "development";

//disable context menu for touch in development mode (THIS ONLY WORKS IN CHROME AS OF 2023... fucking pointerType)
if (isDevelopment) {
  window.oncontextmenu = function (event: any) {
    const pointerEvent = event as PointerEvent;
    if (pointerEvent.pointerType === "touch") {
      // context menu was triggered by long press
      return false;
    }
    // just to show that pointerEvent.pointerType has another value "mouse" aka right click
    if (pointerEvent.pointerType === "mouse") {
      // context menu was triggered by right click
      return true;
    }
    // returning true will show a context menu for other cases
    return true;
  };
}

const App: React.FC = () => {

  return isBrowserSupported() === "UNSUPPORTED" && !isPrerenderer() ?
    <UnsupportedBrowser /> :
    <ErrorBoundary errorChildren={<StartUpPage isFailure={true}/>}>
      <Suspense fallback={<StartUpPage isFailure={false}/>}>
        <AppMain />
      </Suspense>
    </ErrorBoundary>;

};

export default App;
