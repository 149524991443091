import {Set} from "immutable";

//TODO RENAME TO DETECTBOT.TS

const debugFakePrerender: boolean = false;

const crawlerUserAgents: Set<string> = Set([
  "Mediapartners-Google",
  "AdsBot-Google",
  "AdsBot-Google-Mobile",
  "e195-prerender-test",
  "googlebot",
  "Google-InspectionTool",
  "Yahoo! Slurp",
  "bingbot",
  "yandex",
  "baiduspider",
  "facebookexternalhit",
  "twitterbot",
  "rogerbot",
  "linkedinbot",
  "embedly",
  "quora link preview",
  "showyoubot",
  "outbrain",
  "pinterest/0.",
  "developers.google.com/+/web/snippet",
  "slackbot",
  "vkShare",
  "W3C_Validator",
  "redditbot",
  "Applebot",
  "WhatsApp",
  "flipboard",
  "tumblr",
  "bitlybot",
  "SkypeUriPreview",
  "nuzzel",
  "Discordbot",
  "Google Page Speed",
  "Qwantify",
  "pinterestbot",
  "Bitrix link preview",
  "XING-contenttabreceiver",
  "Chrome-Lighthouse",
  "TelegramBot",
  "SeznamBot",
  "screaming frog SEO spider",
  "AhrefsBot",
  "AhrefsSiteAudit",
  "Iframely",
  "Neevabot",
  "PerplexityBot",
]);

const isCrawlerBot = (): boolean => {
  if (typeof navigator !== "undefined") {
    const lcUserAgent = navigator.userAgent.toLowerCase();
    return crawlerUserAgents.some(crawlerUserAgent => lcUserAgent.includes(crawlerUserAgent));
  } else {
    return false;
  }
};

export const isPrerenderer = (): boolean => {
  return debugFakePrerender || (
    typeof navigator !== "undefined" &&
    (navigator.userAgent.includes("seo4ajax") ||
    navigator.userAgent.includes("Prerender") ||
    navigator.userAgent.includes("prerender"))
  );
};

export const isCrawler: boolean = isCrawlerBot();
export const isPrerender: boolean = isPrerenderer();