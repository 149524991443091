import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";

export const prerenderDebugLogging: boolean | "trace" = false;

const logPrerenderIo: boolean = true;
const logSeo4Ajax: boolean = false;

type Service = "PrerenderIO" | "Seo4Ajax";

type PrerenderCallerProps = {
  caller: React.ComponentType<any> | string;
};

const debugLog = (message: string, caller: React.ComponentType<any> | string, service: Service): void => {
  //@ts-ignore
  if (prerenderDebugLogging && ((service === "PrerenderIO" && logPrerenderIo) || (service === "Seo4Ajax" && logSeo4Ajax))) {
    const name = typeof caller === "string" ? caller : caller.name;
    //@ts-ignore
    if (prerenderDebugLogging === "trace") {
      console.trace(`${message} (${name})`);
    } else {
      console.log(`${message} (${name})`);
    }
  }
};

export const NotReadyForPrerender: React.FC<PrerenderCallerProps> = (props: PrerenderCallerProps) => {

  useEffect(() => {
    debugLog("window.prerenderReady = false", props.caller, "PrerenderIO");
    debugLog("window.willCallOnCaptureReady = true", props.caller, "Seo4Ajax");
  }, [props.caller]);

  return (
    <Helmet>
      <script> window.willCallOnCaptureReady = true; </script>
      <script> window.prerenderReady = false; </script>
    </Helmet>
  );

};

export const readyForPrerender = (caller: React.ComponentType<any> | string) => {
  debugLog("window.prerenderReady = true", caller, "PrerenderIO");
  if (window.onCaptureReady) {
    debugLog("window.onCaptureReady()", caller, "Seo4Ajax");
    window.onCaptureReady(); //seo4ajax
  } else {
    debugLog("window.onCaptureReady() called, though DOES NOT EXIST", caller, "Seo4Ajax");
  }
  window.prerenderReady = true; //prerender.io
};

type PrerenderFailureProps = {
  statusCode: number;
} & PrerenderCallerProps;

export const PrerenderFailure: React.FC<PrerenderFailureProps> = (props: PrerenderFailureProps) => {

  useEffect(() => {
    if (window.onCaptureReady) {
      window.onCaptureReady(props.statusCode);
    }
  }, [props.statusCode]);

  useEffect(() => {
    debugLog(`FAILURE: prerender-status-code = ${props.statusCode}`, props.caller, "PrerenderIO");
    if (!window.onCaptureReady) {
      debugLog(`FAILURE: window.onCaptureReady(${props.statusCode}) called, though DOES NOT EXIST`, props.caller, "Seo4Ajax");
    } else {
      debugLog(`FAILURE: window.onCaptureReady(${props.statusCode}) called`, props.caller, "Seo4Ajax");
    }
  }, [props.caller, props.statusCode]);

  return (
    <Helmet>
      <meta name={"prerender-status-code"} content={`${props.statusCode}`} />
    </Helmet>
  );

};

type PrerenderRedirectProps = {
  redirectUrl: string;
} & PrerenderCallerProps;

export const PrerenderRedirect: React.FC<PrerenderRedirectProps> = (props: PrerenderRedirectProps) => {

  useEffect(() => {
    debugLog(`REDIRECT: prerender-location ${props.redirectUrl}`, props.caller, "PrerenderIO");
  }, [props.caller, props.redirectUrl]);

  return (
    <Helmet>
      <meta name={"prerender-status-code"} content={"301"} />
      <meta name={"prerender-header"} content={`Location: ${props.redirectUrl}`} />
    </Helmet>
  );

};