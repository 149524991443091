import {NotReadyForPrerender} from "./common/prerender";
import styled from "styled-components";
import {browserResult} from "./browsers";

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 40px 30px 30px 30px;
  box-sizing: border-box;
  background-color: #f7f4f2;
`;

const HeadingTypography = styled.h1`
  font-size: 1.2rem;
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #2e318b;
  text-align: center;
`;

const Typography = styled.p`
  font-size: 1rem;
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #2e318b;
  text-align: center;
`;

const DetailDiv = styled.div`
  position: absolute;
  bottom: 40px;
  left: 30px;
`;

const DetailTypography = styled.p`
  font-size: 0.5rem;
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #2e318b;
`;

const UnsupportedBrowser = () => {

  return (
    <>
      <NotReadyForPrerender caller={"unsupportedbrowser"}/>
      <Background>
        <HeadingTypography>
          You need to update your browser to use Explore195.
        </HeadingTypography>
        <Typography>
          Trust us... it's worth it!
        </Typography>
        {
          browserResult &&
            <DetailDiv>
              <DetailTypography>
                {"Current browser: " + browserResult.displayName + " " + browserResult.version}
              </DetailTypography>
            </DetailDiv>
        }
      </Background>
    </>
  );

};

export default UnsupportedBrowser;