import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import {StyleSheetManager} from "styled-components";
import {CookiesProvider} from "react-cookie";
import {NotReadyForPrerender} from "./common/prerender";
import App from "./App";
import {isPrerenderer} from "./detectPrerenderer";
import {HelmetProvider} from "react-helmet-async";

ReactDOM.render(
  <>
    <React.StrictMode>
      <StyleSheetManager
        disableCSSOMInjection={isPrerenderer()} //https://github.com/prerender/prerender/issues/574
      >
        <HelmetProvider>
          <CookiesProvider>
            <NotReadyForPrerender caller={"index"}/>
              <App />
          </CookiesProvider>
        </HelmetProvider>
      </StyleSheetManager>
    </React.StrictMode>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
